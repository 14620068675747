@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  padding: 0;
}

:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}
a:hover {
  color: #535bf2;
}

body {
  margin: 0;
  display: flex;
  place-items: center;
  min-width: 320px;
  min-height: 100vh;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.rdt_TableBody{
  height: 68vh !important;
}


.dropdown:hover .dropdown-menu {
  width: 100px;
  display: block;
}

.dropdown:hover .dropdown-menuOne {
  width: 80px;
  display: block;
  list-style: none;
}

.dropdown:hover > .dropdown-content {
  width: 100%;
  display: block;
}

.model {
  transition: all 5s ease-in-out;
}

::-webkit-scrollbar {
  width: 6px;
  height: .5px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px rgb(167, 163, 163); */
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bad2d1;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #048178;
}

._2pZMF{
  display: none !important;
} 


@media only screen and (max-width: 768px) {
  .my-modal {
    width: 100% !important; /* override the width of the modal */
  }
  .cus-ant-progress-inner{
    width: 80px !important;
    height: 80px !important;
  }
}


.dropdown:hover .dropdown-menu {
  display: block;
}

.ant-layout-header{
  padding-inline: 20px !important;
}


.login-image{
  background-image: url('assets/images/loginPage.jpg');
  background-position: center;
  background-size: cover;
}

.upload-bg {
  background-image: url('assets/images/uploadImage-bg.png');
  background-position: center;
  background-size: cover;
}